var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    _vm._b(
      {
        attrs: {
          id: "core-navigation-drawer",
          dark:
            _vm.barColor !== "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)",
          "expand-on-hover": _vm.expandOnHover,
          right: _vm.$vuetify.rtl,
          src: _vm.barImage,
          "mobile-break-point": "960",
          app: "",
          width: "260"
        },
        scopedSlots: _vm._u([
          {
            key: "img",
            fn: function(props) {
              return [
                _c(
                  "v-img",
                  _vm._b(
                    { attrs: { gradient: "to bottom, " + _vm.barColor } },
                    "v-img",
                    props,
                    false
                  )
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.drawer,
          callback: function($$v) {
            _vm.drawer = $$v
          },
          expression: "drawer"
        }
      },
      "v-navigation-drawer",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-list-item",
        { attrs: { "two-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "text-uppercase font-weight-regular display-2" },
                [
                  _c(
                    "span",
                    { staticClass: "logo-normal" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/crm-logo-white-alpha.png"),
                          "max-width": "211"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-1" }),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        [
          _c("base-item-group", {
            staticClass: "user-profile",
            attrs: { item: _vm.profile }
          })
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-list",
        { attrs: { expand: "", nav: "" } },
        [
          _c("div"),
          _vm._l(_vm.computedItems, function(item, i) {
            return [
              item.children
                ? _c("base-item-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.hide,
                        expression: "!item.hide"
                      }
                    ],
                    key: "group-" + i,
                    attrs: { item: item }
                  })
                : _c("base-item", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.hide,
                        expression: "!item.hide"
                      }
                    ],
                    key: "item-" + i,
                    attrs: { item: item }
                  })
            ]
          }),
          _c("div")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }