<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-normal"><v-img
            src="@/assets/crm-logo-white-alpha.png"
            max-width="211"
          /></span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <base-item-group
        class="user-profile"
        :item="profile"
      />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          v-show="!item.hide"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          v-show="!item.hide"
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<style>
  .user-profile .v-list-item__title::first-letter {
    text-transform:capitalize;
  }
</style>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import { getUser, hasRole, hasRoles, OfficeAdmin, OfficeOwner } from '../../../../services/jwt.service'
  import alfaApi from '../../../../services/AlfaApi'
  import actionApi from "@/services/ActionApi";

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      alfaHostname: null,
      actualActions: 0,
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        let user = getUser()
        if (!user) {
          user = { username: 'Invalid Login' }
        }
        return {
          avatar: true,
          group: '',
          title: user.username,
          children: [
            {
              to: 'login',
              title: 'Выйти',
            },
          ],
        }
      },

      alfaCrmLink () {
        return 'https://' + this.alfaHostname
      },

      items () {
        return [
          {
            icon: 'mdi-view-dashboard',
            title: 'Ваш центр',
            to: '/',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-badge-account',
            title: 'Клиенты',
            to: '/clients',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]) || this.alfaHostname,
          },
          {
            icon: 'mdi-alpha',
            title: 'Альфа CRM',
            group: '',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]) || !this.alfaHostname,
            children: [
              {
                icon: 'mdi-flash',
                title: 'Перейти в Альфа CRM',
                href: this.alfaCrmLink,
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-flash',
                title: 'Клиенты',
                to: 'alfa-clients',
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-badge-account',
                title: 'Архив клиентов',
                to: 'clients',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
            ],
          },
          {
            icon: 'mdi-list-status',
            title: this.actualActions > 0 ? 'Задачи [' + this.actualActions + ']' : 'Задачи',
            to: '/actions',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-school',
            title: 'Учебная сетка',
            to: '/lessons',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-calendar-clock',
            title: 'Расписание преподавателей',
            to: '/timeplan',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-cash',
            title: 'Абонементы',
            to: '/subscriptions',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-cash-multiple',
            title: 'Шаблоны абонементов',
            to: '/templates',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-table',
            title: 'Группы',
            to: '/timetables',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },

          {
            icon: 'mdi-human-female',
            title: 'Преподаватели',
            to: '/teachers',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
          {
            icon: 'mdi-office-building',
            title: 'Помещения',
            href: process.env.VUE_APP_OLD_URL + 'admin/room/admin',
            hide: !hasRole(OfficeOwner),
          },
          {
            icon: 'mdi-web',
            title: 'Сайт',
            group: '',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
            children: [
              {
                icon: 'mdi-flash',
                title: 'Информация о центре',
                href: process.env.VUE_APP_OLD_URL + 'admin/office/update/',
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-newspaper',
                title: 'Новости',
                to: 'newses',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
              {
                icon: 'mdi-cash-register',
                title: 'Цены на обучение',
                to: 'prices',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
              {
                icon: 'mdi-comment-multiple',
                title: 'Отзывы',
                to: 'mentions',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
              {
                icon: 'mdi-account-plus',
                title: 'Лицензии',
                to: 'patents',
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-account-plus',
                title: 'Вакансии',
                href: process.env.VUE_APP_OLD_URL + 'admin/vacancy/admin',
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-youtube-tv',
                title: 'Видеоролики',
                href: process.env.VUE_APP_OLD_URL + 'admin/officevideo/admin',
                hide: !hasRole(OfficeOwner),
              },
            ],
          },
          {
            icon: 'mdi-help',
            title: 'Обучение и помощь',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
            children: [
              {
                icon: 'mdi-help',
                title: 'Справка',
                target: '_blank',
                href: process.env.VUE_APP_API_URL + 'docs/home.html',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
              {
                icon: 'mdi-video',
                title: 'Вебинары',
                href: process.env.VUE_APP_OLD_URL + 'webinar',
                hide: !hasRoles([OfficeOwner, OfficeAdmin]),
              },
              {
                icon: 'mdi-book-open-page-variant',
                title: 'База знаний',
                href: process.env.VUE_APP_OLD_URL + 'admin/document/index',
                hide: !hasRole(OfficeOwner),
              },
              {
                icon: 'mdi-certificate',
                title: 'Тесты',
                href: process.env.VUE_APP_OLD_URL + 'admin/document/index',
                hide: !hasRole(OfficeOwner),
              },
            ],
          },

          {
            icon: 'mdi-face-agent',
            title: 'Вопросы: care@poliglotiki.ru',
            href: 'mailto:care@poliglotiki.ru',
            hide: !hasRoles([OfficeOwner, OfficeAdmin]),
          },
        ]
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    mounted () {
      alfaApi.getAlfaHostname()
        .then(response => {
          this.alfaHostname = response
        })
        .catch(error => {
          this.handleError(error)
        })

      actionApi.getActiveActionsCount()
        .then(response => {
          this.actualActions = response
        })
        .catch(error => {
          this.handleError(error)
        })
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
          // hide: !this.$t(item.visible),
        }
      },

    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
